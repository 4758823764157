import './Contact.scss';
import React from 'react';
import { useForm} from '../../hooks/useForm';
import { useState } from 'react';
import emailjs from 'emailjs-com';

export function Contact() {
    const [userMessage, setMessage] = useState('');
    const [isHideMessage, setHideMessage] = useState(true);
    
    const showMessage = (msg) => {
        setMessage(msg);
        setHideMessage(false)
        setTimeout(()=>setHideMessage(true),3000)
    };

    const resetForm = () => {
        formToEdit.name = '';
        formToEdit.email = '';
        formToEdit.message = '';
        handleChange({ target: {} });
    };

    const onSendMessage = async (ev) => {
        ev.preventDefault();
        try {
            const res = await emailjs.sendForm('service_hjn7mqm', 'contact_form', ev.target, 'user_4JvcDwuBJ3bcdhgz2bHWr');
            showMessage('Thank you, your message has been sent');
            resetForm();
        } catch {
            showMessage('Something weng wrong, please try again.');
        }
    };

    const [formToEdit, handleChange] = useForm({ name: '', email: '', message: '' });
    let { name, email, message } = formToEdit;

    return (
        <div className="main-layout contact">
            <div className="page-headline">
                <h3 className="page-title">
                    Contact<span>.</span>
                </h3>
                <p className="large">Feel free to reach out</p>
            </div>
            <form onSubmit={onSendMessage} className="form-layout">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" value={name} onChange={handleChange} required />
                <label htmlFor="email">E-mail</label>
                <input type="email" id="email" name="email" value={email} onChange={handleChange} required />
                <label htmlFor="message">Your message</label>
                <textarea id="message" name="message" value={message} onChange={handleChange}></textarea>
                <button className="btn action">Submit</button>
            </form>
            <nav className="flex space-between media">
                <a href="mailto:ifat.3d@gmail.com" target="_blank" rel="noreferrer" className="icon solid email">
                    <span>Email Ifat</span>
                </a>
                <a href="https://www.linkedin.com/in/ifat-braslavi" rel="noreferrer" target="_blank" className="icon brand linkedin">
                    <span>Checkout Ifats Linkedin</span>
                </a>
                <a href="https://github.com/ifatBR" target="_blank" rel="noreferrer" className="icon brand github">
                    <span>Checkout Ifats Github</span>
                </a>
            </nav>
            <div className="user-message" hidden={isHideMessage}>
                <h5>{userMessage}</h5>
            </div>
        </div>
    );
}
