

import './ProjectList.scss'
import {ProjectPreview} from '../ProjectPreview'
export function ProjectList({projects, layoutClass}) {

    return (
        <ul className={'clean-list ' + layoutClass}>
            {projects.map(project => <ProjectPreview project={project} key={project.id}/>)}
        </ul>
    )
}

