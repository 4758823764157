import './Homepage.scss';
import React  from 'react';
import { NavLink } from 'react-router-dom';
export function Homepage() {

    return (
        <div className="flex main-layout homepage">
         
            <div className="img-container">
                <img src={require('../../assets/img/me_bright_pic.jpg').default} className="me-pic" alt="" />
                <img src={require('../../assets/img/me_bright_sketch.jpg').default} className="me-sketch" alt="" />
            </div>

            <div className="flex align-center space-between text-container">
                <div className="flex column align-start">
                    <h1 className="p1">HI</h1>
                    <h1 className="p2">IM</h1> 
                    <h1 className="p3">IFAT<span>.</span></h1>
                    <h4>Full-stack/Frontend developer</h4>
                </div>
                <NavLink to="/projects" className="start"></NavLink>
            </div>
        </div>
    );
}
