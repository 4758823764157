// import { NavLink } from 'react-router-dom';
import './ProjectPreview.scss';

export function ProjectPreview({ project }) {
    return (
        <li className="flex space-between project-preview">
            <div>
                <h4>{project.name}</h4>
                <p>{project.description}</p>
            </div>
            <div className="thumbnail">
                <img src={require('../../assets/img/' + project.imgSrc).default} alt=""/>
                {project.tools && (
                    <div className="card-w-btns-layout">
                        <div className="flex justify-center wrap tools">
                            {project.tools.map((tool, idx) => (
                                <h5 className="tool" key={'t' + idx}>
                                    {tool}
                                </h5>
                            ))}
                        </div>
                        <div className="flex justify-center align-center btn-container">
                            <a href={project.gitUrl} target="_blank" rel="noreferrer" className="btn icon brand github" title="git"><span>{`Link to git repository of project: ${project.name}`}</span></a>
                            <a href={project.url} target="_blank" rel="noreferrer"  className="btn icon solid site" title="website"><span>{`Link to url of project: ${project.name}`}</span></a>
                        </div>
                    </div>
                )}
            </div>
        </li>
    );
}
