import React from 'react';
import { AppHeader } from './cmps/AppHeader';
import { About } from './pages/About/About';
import { Contact } from './pages/Contact/Contact';
import { Homepage } from './pages/Homepage/Homepage';
import { Projects } from './pages/Projects/Projects';
import './scss/main.scss';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

function App() {

    return (
        <Router>
            <div className="App">
                <AppHeader />
                <Switch>
                    <Route component={About} path="/about"></Route>
                    <Route component={Projects} path="/projects"></Route>
                    <Route component={Contact} path="/contact"></Route>
                    <Route component={Homepage} path="/"></Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
