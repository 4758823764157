import './About.scss';
import React from 'react';
import { ProjectList } from '../../cmps/ProjectList/ProjectList';

export function About() {
    const imgPath = 'about/';

    const aboutPoints = [
        {
            id: 'a101',
            imgSrc: imgPath + 'e2e2.svg',
            title: 'e2e',
            description: `End to end projects - from server to client.
            I am happy to create apps from scratch or join into any part of the pipeline.`,
        },
        {
            id: 'a102',
            imgSrc: imgPath + 'design2.svg',
            title: 'design',
            description: `Design - As a former architect, I believe that good design equals aesthetics, precision and coherence.`,
        },
        {
            id: 'a103',
            imgSrc: imgPath + 'quality2.svg',
            title: 'quality',
            description: `Quality - I find great joy in creating high quality solutions, while writing comprehensive reusable code.`,
        },
    ];

    const skills = [
        'Vue.js',
        'Vuex',
        'React.js',
        'Redux',
        'Angular',
        'HTML5',
        'ES6', 
        'CSS3',
        'SCSS',
        'Pixel Perfect',
        'Single Page Apps',
        'Responsive Design',
        'Node.js',
        'Express',
        'MongoDB',
        'Websockets',
        'REST API',
        'Git',
    ];
    return (
        <div className="main-layout about">
            <div className="page-headline">
                <h3 className="page-title">
                    About<span>.</span>
                </h3>
                <div className="sub-title">
                    <p className="large">Ifat Braslavi -</p>
                    <p className="large">Technical, Creative, Curious</p>
                </div>
            </div>

            <ProjectList projects={aboutPoints} layoutClass="icons-layout" />
            <div className="skills-container">
                <div className="skills">
                    <h4>Skills</h4>
                    <ul className="clean-list flex wrap skill-list">
                        {skills.map((skill,idx) => (
                            <p key={'s'+idx}>{skill}</p>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
