import './Projects.scss';
import React from 'react';
import { ProjectList } from '../../cmps/ProjectList/ProjectList';

export function Projects() {
    const imgPath = 'projects/';

    const projects = [
        {
            id: 'p101',
            name: 'Prello',
            imgSrc: imgPath + 'prello.jpg',
            description: 'A task management application Based on Trello, created over the course of 2 weeks.',
            tools: ['Vue.js', 'Vuex', 'Sass', 'Node.js', 'MongoDB', 'Express', 'Websockets'],
            url: 'https://prello-app.herokuapp.com',
            gitUrl: 'https://github.com/ifatBR/prello',
        },
        {
            id: 'p102',
            name: 'Mr.Bitcoin',
            imgSrc: imgPath + 'mr-bitcoin.jpg',
            description: 'A simple virtual wallet, allowing for signup, searching through contacts and transfering money to contacts.',
            tools: ['React', 'Redux', 'Thunk', 'Sass', 'MongoDB', 'Express'],
            url: 'https://mrbitcoin-app.herokuapp.com',
            gitUrl: 'https://github.com/ifatBR/mr-bitcoin_react',
        },
        {
            id: 'p103',
            name: 'Memegen',
            imgSrc: imgPath + 'memegen.jpg',
            description: 'A meme generator, allowing the user to upload their own pictures and afterwards download, save or publish their creation.',
            tools: ['Vanilla JS', 'HTML', 'Canvas', 'CSS'],
            url: 'https://ifatbr.github.io/meme-generator/',
            gitUrl: 'https://github.com/ifatBR/meme-generator',
        },
        {
            id: 'p104',
            name: 'Minesweeper',
            imgSrc: imgPath + 'minesweeper.jpg',
            description: 'A variation of the good old minesweeper game, with some added features like: hints, undo, safe click and placing the mines on the board. (Not responsive).',
            tools: ['Vanilla JS','HTML', 'CSS' ],
            url: 'https://ifatbr.github.io/Minesweeper/',
            gitUrl: 'https://github.com/ifatBR/Minesweeper',
        },
    ];
    return (
        <div className="main-layout">
            <div className="page-headline">
                <h3 className="page-title">Projects<span>.</span></h3>
                <p className="large">I have exprience with a variety of relevant tools,
                    check out my work
                </p>
            </div>
            <ProjectList projects={projects} layoutClass="card-layout" />
        </div>
    );
}
