import React from 'react';
import { NavLink } from 'react-router-dom';
import './AppNav.scss';

export function AppNav({isNavOpen, toggleNav}) {
    return (
            <ul className={`flex clean-list app-nav ${isNavOpen? 'nav-open':''}`}>
                <li><NavLink to="/" activeClassName="selected" exact onClick={toggleNav}>Home</NavLink> </li>
                <li><NavLink to="/projects" activeClassName="selected" onClick={toggleNav}>Projects</NavLink></li>
                <li><NavLink to="/about" activeClassName="selected" onClick={toggleNav}>About</NavLink></li>
                <li><NavLink to="/contact" activeClassName="selected" onClick={toggleNav}>Contact</NavLink></li>
            </ul>
    );
}
