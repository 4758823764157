import './AppHeader.scss';
import React, { useEffect, useRef, useState } from 'react';
import { AppNav } from '../AppNav/AppNav';
import { useLocation } from 'react-router-dom';

export function AppHeader() {
    const loc = useLocation();
    const showHeader = useRef();
    const [isNavOpen, setIsNavOpen] = useState(false)
    showHeader.current = loc.pathname !== '/';

    useEffect(() => {
        showHeader.current = loc.pathname !== '/';
    }, [loc]);
    
    const toggleNav = () =>{
        setIsNavOpen(!isNavOpen)
    }

    return (
        showHeader.current && (
            <header className={`flex space-between align-center app-header ${isNavOpen? 'nav-open':''}`} >
                <img src={require("../../assets/img/logo.png").default} className="logo" alt="logo" />
                <div className="hamburger" onClick={toggleNav}></div>
                <AppNav isNavOpen={isNavOpen} toggleNav={toggleNav}/>
                <div className="nav-screen" onClick={toggleNav}></div>
            </header>
        )
    );
}
